import React from 'react'
import { ModalProvider } from 'uikit'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { getLibrary, getLibrarySwap } from 'utils/web3React'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { LanguageProvider } from 'contexts/Localization'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import store from 'state'
import { ApolloProvider } from 'react-apollo'
import { NetworkContextName } from './config'
import { client } from './views/Analytics/apollo/client';
import LocalStorageContextProvider, { Updater as LocalStorageContextUpdater } from './contexts/Analytics/LocalStorage'
import TokenDataContextProvider, { Updater as TokenDataContextUpdater } from './contexts/Analytics/TokenData'
import GlobalDataContextProvider from './contexts/Analytics/GlobalData'
import PairDataContextProvider, { Updater as PairDataContextUpdater } from './contexts/Analytics/PairData'
import ApplicationContextProvider from './contexts/Analytics/Application'
import UserContextProvider from './contexts/Analytics/User'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)


const Providers: React.FC = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <Web3ReactProvider getLibrary={getLibrarySwap}>
        <Web3ProviderNetwork getLibrary={getLibrarySwap}>
          <Provider store={store}>
            <ToastsProvider>
              <HelmetProvider>
                <ThemeContextProvider>
                  <LanguageProvider>
                    <RefreshContextProvider>
                      <LocalStorageContextProvider>
                        <ApplicationContextProvider>
                          <TokenDataContextProvider>
                            <GlobalDataContextProvider>
                              <PairDataContextProvider>
                                <UserContextProvider>
                                  <ModalProvider>{children}</ModalProvider>
                                </UserContextProvider>
                              </PairDataContextProvider>
                            </GlobalDataContextProvider>
                          </TokenDataContextProvider>
                        </ApplicationContextProvider>
                      </LocalStorageContextProvider>
                    </RefreshContextProvider>
                  </LanguageProvider>
                </ThemeContextProvider>
              </HelmetProvider>
            </ToastsProvider>
          </Provider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </ApolloProvider>
  )
}

export default Providers
