import { Colors } from './types'

export const baseColors = {
  failure: '#53DEE9',
  primary: '#44C4E2',
  primaryBright: '#53DEE9',
  primaryDark: '#0D1D36',
  button: '#313154',
  secondary: '#C1272D',
  success: '#31D0AA',
  warning: '#FFB237',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#9797D3',
  nav: '#081933',
  yellow2: '#c7f2f9',
  link: '#c7f2f9',
  blue: '#44c4e2',
}

export const additionalColors = {
  binance: '#F0B90B',
  overlay: 'rgba(0,0,0,0.9)',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: '#FAF9FA',
  backgroundDisabled: '#1d5663',
  backgroundAlt: '#FFFFFF',
  borderColor: "#F6F6F6",
  cardBorder: '#E7E3EB',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  input: '#eeeaf4',
  inputSecondary: '#d7caec',
  tertiary: '#EFF4F5',
  text: '#452A7A',
  textDisabled: '#BDC2C4',
  textSubtle: '#8f80ba',
  disabled: '#E9EAEB',

  backgroundColor: 'transparent',
  thugswapYellow: 'black',
  inputBackground: '#FAFAFA',
  shadowColor: '#2F80ED',
  mercuryGray: '#E1E1E1',
  text1: 'transparent',
  text2: '#565A69',
  text3: '#E0E0E0',
  white: '#E0E0E0',
  bg1: '#E0E0E0',
  bg2: '#F7F8FA',
  bg3: '#EDEEF2',
  bg5: '#888D9B',
  bg6: '#E0E0E0',
  advancedBG: 'rgba(255,255,255,0.4)',
  divider: 'rgba(43, 43, 43, 0.035)',
  primary1: '#44c4e2',

  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  secondary: '#C1272D',
  background: '#152B4E',
  backgroundDisabled: '#0a4b58',
  backgroundAlt: '#27262c',
  borderColor: "#F6F6F6",
  cardBorder: '#383241',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#2e3436',
  inputSecondary: '#262130',
  primaryDark: '#0D1D36',
  tertiary: '#353547',
  text: '#E0E0E0',
  textDisabled: '#918b9c',
  textSubtle: '#C7F2F9',
  disabled: '#524B63',

  backgroundColor: 'transparent',
  thugswapYellow: '#44c4e2',
  inputBackground: '#1F1F1F',
  shadowColor: '#000',
  mercuryGray: '#333333',
  text1: '#E0E0E0',
  text2: '#C3C5CB',
  text3: '#6C7284',
  white: '#E0E0E0',
  bg1: 'transparent',
  bg2: 'transparent',
  bg3: '#40444F',
  bg5: '#000000',
  bg6: 'transparent',
  advancedBG: 'rgba(0,0,0,0.1)',
  divider: 'rgba(43, 43, 43, 0.435)',
  primary1: '#44c4e2',

  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}
