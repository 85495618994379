export default {
  alloy: {
    97: '0xC5FD6F3eB1f63082Daee9e09E17D870005962309',
    56: '0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
  },
  jump: {
    97: '0x2c9a2fA5d93c7A4CFfBB45e94f05Fd9eF58A5CE2',
    56: '',
  },
  hypr: {
    97: '0x03D6BD3d48F956D783456695698C407A46ecD54d',
    56: '0x03d6bd3d48f956d783456695698c407a46ecd54d',
  },
  ori: {
    250: '0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
  },
  aurora: {
    250: '0xbc2451AaD349b6B43FD05F4F0cC327F8A6bcA2d4',
  },
  mech: {
    97: '0xcba0FA601C44509e671283d071c600EFA7EdDC6a',
    56: '0x3ae713C662B8852D686e718E0762631A4CB84954',
    250: '0x85c85647e1A79c2b8bc3Ed2B6a1DdE326eeC66c5',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  usdc: {
    250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    250: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
  },
  wftm: {
    250: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
  },
  masterChef: {
    56: '0x4F1818Ff649498a2441aE1AD29ccF55a8E1C6250',
    97: '0x683fE36D7e543181ba19101283B7Ed3c2B3CD749', // v2 testnet farm
    250: '0x90Df158ff7c31aD1d81ddDb1D8ab9d0eCBCeDa20',
  },
  multiCall: {
    56: '0x1282fE78d092E6077B05FeE27f239b49b2725978',
    250: '0x6185A664e90754F4967B9962Fe7B1183b147fc48',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  lottery: {
    56: '0x96f83a5A3572f38D9Bf147973B132820Ea4446f4',
    250: '0x1e8763f80E57209E26300b3002bbAEAd7CEA3Bd1', // '0x98fA02eC9Feafe8ABf3AD71693BC5D562aa38f95',
    97: '',
  },
}
