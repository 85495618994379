import { BNB, ChainId, CurrencyAmount, FANTOM, Network } from '@hyperjump-defi/sdk'
import tokens from 'config/constants/tokens'

const networks = [
  {
    name: 'BSC',
    network: Network.BSC,
    id: 56,
    url: process.env.REACT_APP_BSC_BASE_URL,
    networkTokenUsdFarmPid: 3,
    farmingTokenUsdFarmPid: 27,
    farmingTokenNetworkTokenFarmPid: 1,
    govTokenNetworkTokenFarmPid: 2,
    coreFarms: [1, 2, 3, 27],
    farmingToken: tokens.alloy,
    govToken: tokens.hypr,
    networkToken: tokens.bnb,
    wrappedNetworkToken: tokens.wbnb,
    timerScale: 3,
    farmMultiplierScale: 10,
    baseCurrencyAmount: CurrencyAmount.bnb,
    baseCurrency: BNB,
    swapTokenListUrl: 'https://tokens.hyperjump.app/tokens.json',
    scannerName: 'BSC Scan',
    approvalDomainName: 'Thugswap Street LP',
    votingLink: 'https://snapshot.org/#/bsc.hyperjumpdao.eth',
    bridgePath: 'https://www.binance.org/en/bridge?utm_source=Hyperjump',
    contractsLink: 'https://hyperjump.fi/contracts-bsc.html',
    infoLink: 'https://bsc-info.hyperjump.app',
    supportedChainIds: [ChainId.BSC_MAINNET],
    vaultCompoundTime: '8 hours',
  },
  {
    name: 'BSC_TESTNET',
    network: Network.BSC_TESTNET,
    id: 97,
    url: process.env.REACT_APP_BSC_TESTNET_BASE_URL,
    networkTokenUsdFarmPid: 3, // BNB BUSD
    farmingTokenUsdFarmPid: 2, // JUMP BUSD
    farmingTokenNetworkTokenFarmPid: 1, // JUMP BNB
    govTokenNetworkTokenFarmPid: 1,
    coreFarms: [1, 2, 3],
    farmingToken: tokens.jump,
    govToken: tokens.hypr,
    networkToken: tokens.bnb,
    wrappedNetworkToken: tokens.wbnb,
    timerScale: 3,
    farmMultiplierScale: 10,
    baseCurrencyAmount: CurrencyAmount.bnb,
    baseCurrency: BNB,
    swapTokenListUrl: 'https://tokens.hyperjump.app/bscTestnetTokens.json',
    scannerName: 'BSC Scan',
    approvalDomainName: 'Thugswap Street LP',
    votingLink: 'https://snapshot.org/#/bsc.hyperjumpdao.eth',
    bridgePath: 'https://www.binance.org/en/bridge?utm_source=Hyperjump',
    contractsLink: 'https://hyperjump.fi/contracts-bsc.html',
    infoLink: '/analytics',
    supportedChainIds: [ChainId.BSC_TESTNET],
    vaultCompoundTime: '8 hours',
  },
  {
    name: 'FTM',
    network: Network.FANTOM,
    id: 250,
    url: process.env.REACT_APP_FTM_BASE_URL,
    networkTokenUsdFarmPid: 3,
    farmingTokenUsdFarmPid: 8,
    farmingTokenNetworkTokenFarmPid: 1,
    govTokenNetworkTokenFarmPid: 2,
    coreFarms: [1, 2, 3, 8],
    farmingToken: tokens.ori,
    govToken: tokens.aurora,
    networkToken: tokens.ftm,
    wrappedNetworkToken: tokens.wftm,
    timerScale: 1,
    farmMultiplierScale: 100,
    baseCurrencyAmount: CurrencyAmount.ftm,
    baseCurrency: FANTOM,
    swapTokenListUrl: 'https://tokens.hyperjump.app/ftmswap.json',
    scannerName: 'FTM Scan',
    approvalDomainName: 'HyperSwap LP',
    votingLink: 'https://snapshot.org/#/ftm.hyperjumpdao.eth',
    bridgePath: '',
    contractsLink: 'https://hyperjump.fi/contracts-ftm.html',
    infoLink: 'https://ftm-info.hyperjump.fi',
    supportedChainIds: [ChainId.FTM_MAINNET],
    vaultCompoundTime: '15 minutes',
  },
]

export default networks
