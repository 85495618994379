import { Network } from '@hyperjump-defi/sdk'
import tokens from './tokens'
import { FarmConfig } from './types'

const farms: Record<Network, FarmConfig[]> = {
  [Network.BSC_TESTNET]: [
    {
      pid: 0,
      lpSymbol: 'JUMP',
      lpAddresses: {
        97: '0x2c9a2fA5d93c7A4CFfBB45e94f05Fd9eF58A5CE2',
      },
      token: tokens.jump,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 1,
      lpSymbol: 'JUMP-BNB',
      lpAddresses: {
        97: '0x68ed8ec86a1d0d367f7462dc9ea1644970160376',
      },
      token: tokens.jump,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 2,
      lpSymbol: 'JUMP-BUSD',
      lpAddresses: {
        97: '0xA49c5526225034214AD8fC3b19Dc6bDcf58B487C',
      },
      token: tokens.jump,
      quoteToken: tokens.busd,
    },
    {
      pid: 3,
      lpSymbol: 'BUSD-BNB',
      lpAddresses: {
        97: '0x81aca0c535bb13e0cd2f10a3c6e4dc1b9bbe6c0e',
      },
      token: tokens.busd,
      quoteToken: tokens.wbnb,
    },
  ],
  [Network.BSC]: [
    /**
     * These 3 farms (PID 0, 1, 3) should always be at the top of the list.
     */
    {
      pid: 0,
      lpSymbol: 'ALLOY',
      lpAddresses: {
        56: '0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      },
      token: tokens.alloy,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 1,
      lpSymbol: 'ALLOY-BNB',
      lpAddresses: {
        97: '0xd94966164b5247A202c98C64F0B929715D6c1916',
        56: '0xf0696E201D20b553792ac2578429B791625308A0',
      },
      token: tokens.alloy,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 3,
      lpSymbol: 'BUSD-BNB',
      lpAddresses: {
        97: '0x81aCa0c535bb13e0cD2F10A3c6E4DC1b9Bbe6c0e',
        56: '0xf2e4e3f9b58b3edac88ad11d689a23f3119a782d',
      },
      token: tokens.busd,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 2,
      lpSymbol: 'HYPR-BNB',
      lpAddresses: {
        97: '0x2Ca6FC658b4ca5Ea03b488C77D00DD5a746B9A6a',
        56: '0x3701cfe08a3f0b629dbcaeceba773a8b4f0044e3',
      },
      token: tokens.hypr,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 27,
      lpSymbol: 'ALLOY-BUSD',
      lpAddresses: {
        56: '0x6e94fb9c08f7e651bf0ed732708e5f148db061c6',
      },
      quoteToken: tokens.alloy,
      token: tokens.busd,
    },
    {
      pid: 4,
      lpSymbol: 'ETH-BNB',
      lpAddresses: {
        97: '0x8963301c9e34d10c4f33f8930e0fae4473a7d3b2',
        56: '0x75115c644f9661a761a333ba0a38e42b1649f143',
      },
      token: tokens.eth,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 5,
      lpSymbol: 'BTC-BNB',
      lpAddresses: {
        97: '0xdfb193940e1317f38e91568fdb05efe18ee4a3c7',
        56: '0xdfb193940e1317f38e91568fdb05efe18ee4a3c7',
      },
      token: tokens.btcb,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 6,
      lpSymbol: 'BIFI-BNB',
      lpAddresses: {
        97: '0x223b53b64aa5f87d2de194a15701fc0bc7474a31',
        56: '0x223b53b64aa5f87d2de194a15701fc0bc7474a31',
      },
      token: tokens.bifi,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 7,
      lpSymbol: 'SPG-BNB',
      lpAddresses: {
        97: '0x153e580760097df78a749bc349dc1ed09bafdbd4',
        56: '0x153e580760097df78a749bc349dc1ed09bafdbd4',
      },
      token: tokens.spg,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 8,
      lpSymbol: 'JGN-BNB',
      lpAddresses: {
        97: '0xc7c4726d0a45e4e7392901a90dcb52ec4d2bd1a3',
        56: '0xc7c4726d0a45e4e7392901a90dcb52ec4d2bd1a3',
      },
      token: tokens.jgn,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 9,
      lpSymbol: 'SOAK-BNB',
      lpAddresses: {
        97: '0x57BdcAc569aD66EAeea7d494e64599086F52cD0F',
        56: '0x57BdcAc569aD66EAeea7d494e64599086F52cD0F',
      },
      token: tokens.soak,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 10,
      lpSymbol: 'SUPRA-BNB',
      lpAddresses: {
        97: '0xA9f2556C9bC830c978F9de5DB67bA40C12A4e9C4',
        56: '0xA9f2556C9bC830c978F9de5DB67bA40C12A4e9C4',
      },
      token: tokens.supra,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 12,
      lpSymbol: 'BTD-ALLOY',
      lpAddresses: {
        97: '0x97243835ac292eac2bc65510bd0615aed36890bd',
        56: '0x97243835ac292eac2bc65510bd0615aed36890bd',
      },
      token: tokens.btd,
      quoteToken: tokens.alloy,
    },
    {
      pid: 13,
      lpSymbol: 'BTD-BUSD',
      lpAddresses: {
        97: '0x1ff3a7890f9c09dc07416aa34658ab22641a902f',
        56: '0x1ff3a7890f9c09dc07416aa34658ab22641a902f',
      },
      token: tokens.btd,
      quoteToken: tokens.busd,
    },
    {
      pid: 11,
      lpSymbol: 'DVT-BNB',
      lpAddresses: {
        97: '0x5EAD38FC2ea515fd17167968c6BB08F3fa4d7a9A',
        56: '0x5EAD38FC2ea515fd17167968c6BB08F3fa4d7a9A',
      },
      token: tokens.dvt,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 76,
      lpSymbol: 'DVT-ALLOY',
      lpAddresses: {
        56: '0xc3CB64CBCA0B0c38F14aaA0b44E9FBb5B2D67E82',
      },
      token: tokens.dvt,
      quoteToken: tokens.alloy,
    },
    {
      pid: 15,
      lpSymbol: 'DITTO-BNB',
      lpAddresses: {
        56: '0x99ce2ec80940d03a30898421654a038e75ab54a8',
      },
      token: tokens.ditto,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 16,
      lpSymbol: 'USDT-BUSD',
      lpAddresses: {
        97: '0x09b6a72cf14067cdd435e4b12caa753404333042',
        56: '0x09b6a72cf14067cdd435e4b12caa753404333042',
      },
      token: tokens.usdt,
      quoteToken: tokens.busd,
    },
    {
      pid: 17,
      lpSymbol: 'HPS-BNB',
      lpAddresses: {
        97: '0xf48d380d09bdcaf84c9e5ebc94e43bdcb02af883',
        56: '0xf48d380d09bdcaf84c9e5ebc94e43bdcb02af883',
      },
      token: tokens.hps,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 18,
      lpSymbol: 'BHC-BNB',
      lpAddresses: {
        97: '0xd46e7f33f8788f87d6017074dc4e4d781d3df91e',
        56: '0xd46e7f33f8788f87d6017074dc4e4d781d3df91e',
      },
      token: tokens.bhc,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 20,
      lpSymbol: 'SLME-ALLOY',
      lpAddresses: {
        97: '0xee4f967f1737a75d50a4d493611d376fc8668961',
        56: '0xee4f967f1737a75d50a4d493611d376fc8668961',
      },
      token: tokens.slime,
      quoteToken: tokens.alloy,
    },
    {
      pid: 21,
      lpSymbol: 'RAMEN-BNB',
      lpAddresses: {
        56: '0x53ad0d1f829a91e09a0fd469b863379ee6644846',
      },
      token: tokens.ramen,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 22,
      lpSymbol: 'RAMEN-ALLOY',
      lpAddresses: {
        56: '0xc85f7878e191da6417a9b2810eed2df074fa9dfc',
      },
      token: tokens.ramen,
      quoteToken: tokens.alloy,
    },
    {
      pid: 23,
      lpSymbol: 'BREW-BNB',
      lpAddresses: {
        56: '0xfe2fe7dacbd26089be277fbae90107e0fbe0438f',
      },
      token: tokens.brew,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 24,
      lpSymbol: 'BREW-ALLOY',
      lpAddresses: {
        56: '0x713f852d740c31476e5686a26db950ece60f436a',
      },
      token: tokens.brew,
      quoteToken: tokens.alloy,
    },
    {
      pid: 25,
      lpSymbol: 'VOODOO-BNB',
      lpAddresses: {
        97: '0xabf46dce113e0bdc4714c21137713b60ede74f12',
        56: '0xabf46dce113e0bdc4714c21137713b60ede74f12',
      },
      token: tokens.voodoo,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 26,
      lpSymbol: 'VOODOO-ALLOY',
      lpAddresses: {
        97: '0x35ba1bb563748cb7050b78cd82e789defdf7881f',
        56: '0x35ba1bb563748cb7050b78cd82e789defdf7881f',
      },
      token: tokens.voodoo,
      quoteToken: tokens.alloy,
    },
    {
      pid: 29,
      lpSymbol: 'OIL-ALLOY',
      lpAddresses: {
        56: '0xaae49c1fabe387a7d872eceb182e2dff2924477a',
      },
      token: tokens.oil,
      quoteToken: tokens.alloy,
    },
    {
      pid: 30,
      lpSymbol: 'INNBC-BNB',
      lpAddresses: {
        97: '0x3b7c817b4cd3e9ad16f89826737098cfef3d1cae',
        56: '0x3b7c817b4cd3e9ad16f89826737098cfef3d1cae',
      },
      token: tokens.innbc,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 31,
      lpSymbol: 'INNBC-ALLOY',
      lpAddresses: {
        97: '0xe235e8ef57c96b71e17814010546a7580f2ee34e',
        56: '0xe235e8ef57c96b71e17814010546a7580f2ee34e',
      },
      token: tokens.innbc,
      quoteToken: tokens.alloy,
    },
    {
      pid: 32,
      lpSymbol: 'BELUGA-BNB',
      lpAddresses: {
        97: '0xe1752d820d2ab5a4027d486b82cf4af137fe525b',
        56: '0xe1752d820d2ab5a4027d486b82cf4af137fe525b',
      },
      token: tokens.beluga,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 33,
      lpSymbol: 'BELUGA-ALLOY',
      lpAddresses: {
        56: '0x208063655593ebe16384881b073ad38282d794a0',
      },
      token: tokens.beluga,
      quoteToken: tokens.alloy,
    },
    {
      pid: 34,
      lpSymbol: 'NAR-BNB',
      lpAddresses: {
        97: '0x1389003bcb228586648721a4f6187004dae01c51',
        56: '0x1389003bcb228586648721a4f6187004dae01c51',
      },
      token: tokens.nar,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 35,
      lpSymbol: 'NAR-ALLOY',
      lpAddresses: {
        97: '0x4a2d3cc966676f6bd912ca72ef0b911f3bcd3bc0',
        56: '0x4a2d3cc966676f6bd912ca72ef0b911f3bcd3bc0',
      },
      token: tokens.nar,
      quoteToken: tokens.alloy,
    },
    {
      pid: 36,
      lpSymbol: 'GFCE-BNB',
      lpAddresses: {
        97: '0x29d0cd756013a929415ecde5c21879a3df03ffc3',
        56: '0x29d0cd756013a929415ecde5c21879a3df03ffc3',
      },
      token: tokens.gfce,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 37,
      lpSymbol: 'GFCE-ALLOY',
      lpAddresses: {
        56: '0x5bc1540f6984020100b68b9ca5dcdf53771d28cb',
      },
      token: tokens.gfce,
      quoteToken: tokens.alloy,
    },
    {
      pid: 38,
      lpSymbol: 'JGN-ALLOY',
      lpAddresses: {
        56: '0x5f4276e7e4c34fba1cfe4145d1c0417c8d223a81',
      },
      token: tokens.jgn,
      quoteToken: tokens.alloy,
    },
    {
      pid: 39,
      lpSymbol: 'SOAK-ALLOY',
      lpAddresses: {
        56: '0x911439fc0438924c2a56e7a849dd018150eed1ab',
      },
      token: tokens.soak,
      quoteToken: tokens.alloy,
    },
    {
      pid: 40,
      lpSymbol: 'SOUL-BNB',
      lpAddresses: {
        56: '0xb326cf38650eafeb97ccf7f652f4d03e905bd040',
      },
      token: tokens.soul,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 41,
      lpSymbol: 'SOUL-ALLOY',
      lpAddresses: {
        56: '0xafa6304a362085ce99a8986893a2c41b24668e79',
      },
      token: tokens.soul,
      quoteToken: tokens.alloy,
    },
    {
      pid: 42,
      lpSymbol: 'TREAT-BNB',
      lpAddresses: {
        97: '0xe5d6d20353fe17e05b16b4b9ed0264d0de8f9966',
        56: '0xe5d6d20353fe17e05b16b4b9ed0264d0de8f9966',
      },
      token: tokens.treat,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 43,
      lpSymbol: 'TREAT-ALLOY',
      lpAddresses: {
        56: '0xa532d578f81a9144bc5598c5db31490c0d9b1460',
      },
      token: tokens.treat,
      quoteToken: tokens.alloy,
    },
    {
      pid: 44,
      lpSymbol: 'xBLZD-BNB',
      lpAddresses: {
        97: '0x91472256ff14320410d1ac5e1d8f066287dd9b23',
        56: '0x91472256ff14320410d1ac5e1d8f066287dd9b23',
      },
      token: tokens.xblzd,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 45,
      lpSymbol: 'xBLZD-ALLOY',
      lpAddresses: {
        56: '0xbe4b54b0f1233088a89466525d574413f6eaf87d',
      },
      token: tokens.xblzd,
      quoteToken: tokens.alloy,
    },
    {
      pid: 46,
      lpSymbol: 'WATCH-BNB',
      lpAddresses: {
        97: '0x88c7266371c51a6e59ac9b27aa27f6ee9981d729',
        56: '0x88c7266371c51a6e59ac9b27aa27f6ee9981d729',
      },
      token: tokens.watch,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 47,
      lpSymbol: 'WATCH-ALLOY',
      lpAddresses: {
        56: '0x45a60fbb8f531e707477bb53287a1691c5383ef7',
      },
      token: tokens.watch,
      quoteToken: tokens.alloy,
    },
    {
      pid: 48,
      lpSymbol: 'BRN-BNB',
      lpAddresses: {
        97: '0xc5ef1ef4d3e8a7186946fce3dd11008124977759',
        56: '0xc5ef1ef4d3e8a7186946fce3dd11008124977759',
      },
      token: tokens.brn,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 49,
      lpSymbol: 'BRN-ALLOY',
      lpAddresses: {
        97: '0xb3e5f7caf715307347dcb12318c2f5b2afad5f2e',
        56: '0xb3e5f7caf715307347dcb12318c2f5b2afad5f2e',
      },
      token: tokens.brn,
      quoteToken: tokens.alloy,
    },
    {
      pid: 50,
      lpSymbol: 'BANANA-BNB',
      lpAddresses: {
        56: '0x2961f37010cfe36c0ed1175c87dea9426c0ea896',
      },
      token: tokens.banana,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 51,
      lpSymbol: 'BANANA-ALLOY',
      lpAddresses: {
        56: '0xdc4786c8a9a6c357793f7f43dffac9d6f0a4794f',
      },
      token: tokens.banana,
      quoteToken: tokens.alloy,
    },
    {
      pid: 52,
      lpSymbol: 'PMP-BNB',
      lpAddresses: {
        56: '0xd7920d054404c91a53e3bd0ec7c1d570c1b78f97',
      },
      token: tokens.pmp,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 53,
      lpSymbol: 'PMP-ALLOY',
      lpAddresses: {
        56: '0x6d795cc94af436436510102de7b17ac9579ce1cb',
      },
      token: tokens.pmp,
      quoteToken: tokens.alloy,
    },
    {
      pid: 55,
      lpSymbol: 'CAKE-ALLOY',
      lpAddresses: {
        56: '0x520b5627235e70747c33cd36d43690c5bb20b999',
      },
      token: tokens.cake,
      quoteToken: tokens.alloy,
    },
    {
      pid: 56,
      lpSymbol: 'MILK2-BNB',
      lpAddresses: {
        56: '0x60900E4EEa37a1deb0b74aF264FE5089A6553644',
      },
      token: tokens.milk2,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 57,
      lpSymbol: 'MILK2-ALLOY',
      lpAddresses: {
        56: '0x69C070790aEA6187dA80966bfbd853b21665e961',
      },
      token: tokens.milk2,
      quoteToken: tokens.alloy,
    },
    {
      pid: 58,
      lpSymbol: 'XDITTO-BNB',
      lpAddresses: {
        56: '0xeca09535293C08b412F9d301a04B3e659E8766FE',
      },
      token: tokens.xditto,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 59,
      lpSymbol: 'XDITTO-ALLOY',
      lpAddresses: {
        56: '0x3BC2EA0814bc784bD570c28b46fD2a1252Ce99Ad',
      },
      token: tokens.xditto,
      quoteToken: tokens.alloy,
    },
    {
      pid: 60,
      lpSymbol: 'GRAND-BNB',
      lpAddresses: {
        56: '0x6725f024f6156f53c6791375a02869a3e366bb52',
      },
      token: tokens.grand,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 61,
      lpSymbol: 'GRAND-ALLOY',
      lpAddresses: {
        56: '0x472A7231FA26D13Aef4a7f60Ee6b13d5fCF2d7C8',
      },
      token: tokens.grand,
      quoteToken: tokens.alloy,
    },
    {
      pid: 62,
      lpSymbol: 'FTS-BNB',
      lpAddresses: {
        56: '0xfa2d34ee51d87d6ee5b6441678b359a215d8c276',
      },
      token: tokens.fts,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 63,
      lpSymbol: 'FTS-ALLOY',
      lpAddresses: {
        56: '0x690D1E37B30ffcCD49F575E4c4d8fe5C7d5D2e2D',
      },
      token: tokens.fts,
      quoteToken: tokens.alloy,
    },

    {
      pid: 64,
      lpSymbol: 'NAUT-BNB',
      lpAddresses: {
        56: '0x1eee92c4e9f796459fb28d2f993069e07aadd8a1',
      },
      token: tokens.naut,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 65,
      lpSymbol: 'NAUT-ALLOY',
      lpAddresses: {
        56: '0x1bcbbaa477bde264b74be40c5e473a3e4595205c',
      },
      token: tokens.naut,
      quoteToken: tokens.alloy,
    },
    {
      pid: 66,
      lpSymbol: 'cCBD-BNB',
      lpAddresses: {
        56: '0xd984191cb77394723fd26ffe4749e9e012eab4fc',
      },
      token: tokens.ccbd,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 67,
      lpSymbol: 'cCBD-ALLOY',
      lpAddresses: {
        56: '0x45c852f282bbce4401de927aba6e620dc5b535f3',
      },
      token: tokens.ccbd,
      quoteToken: tokens.alloy,
    },
    {
      pid: 68,
      lpSymbol: 'sCBD-BNB',
      lpAddresses: {
        56: '0xdee7d8b631e6a52d69345d24d8bb90af53cc4656',
      },
      token: tokens.scbd,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 69,
      lpSymbol: 'sCBD-ALLOY',
      lpAddresses: {
        56: '0x2d86015396b15df7ebd65d507b9fbe2dab088673',
      },
      token: tokens.scbd,
      quoteToken: tokens.alloy,
    },
    {
      pid: 70,
      lpSymbol: 'SHILLING-BNB',
      lpAddresses: {
        56: '0x8eD4EDd3f1E2df216f25875C7c9C3caE83340026',
      },
      token: tokens.shilling,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 71,
      lpSymbol: 'SHILLING-ALLOY',
      lpAddresses: {
        56: '0x5cF3d874775859DD1B297FfBDcFeCB2c70c1DD15',
      },
      token: tokens.shilling,
      quoteToken: tokens.alloy,
    },
    {
      pid: 72,
      lpSymbol: 'ONT-BNB',
      lpAddresses: {
        56: '0x8d0008E53d3843Fae0f1249Fa26d91aB4902bF5e',
      },
      token: tokens.ont,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 73,
      lpSymbol: 'ONT-ALLOY',
      lpAddresses: {
        56: '0x5B8Ba3E399b06e09a203094A3B3227d2dAfa4FbA',
      },
      token: tokens.ont,
      quoteToken: tokens.alloy,
    },
    {
      pid: 74,
      lpSymbol: 'SPHN-BNB',
      lpAddresses: {
        56: '0x402E6B9F9A2959d6f4eb398071d90270a6583C73',
      },
      token: tokens.sphn,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 75,
      lpSymbol: 'SPHN-ALLOY',
      lpAddresses: {
        56: '0x34E676f74fe9118F50E07d9DeCa4AF96f8785397',
      },
      token: tokens.sphn,
      quoteToken: tokens.alloy,
    },
    {
      pid: 77,
      lpSymbol: 'WINGS-BNB',
      lpAddresses: { 56: '0xD1114F6FE1767C217c85eD3DC8570815063Dd9ED' },
      token: tokens.wings,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 78,
      lpSymbol: 'WINGS-ALLOY',
      lpAddresses: { 56: '0xD0A05Cc78f3278Cc6B82EC05Dbf9aB9091EaC473' },
      quoteToken: tokens.alloy,
      token: tokens.wings,
    },
    {
      pid: 79,
      lpSymbol: 'KEIF-ALLOY',
      lpAddresses: {
        56: '0x95633Aa186acFb3666E1d392210E5ea1c2F613C5',
      },
      token: tokens.keif,
      quoteToken: tokens.alloy,
    },
    {
      pid: 80,
      lpSymbol: 'RENBTC-BNB',
      lpAddresses: {
        56: '0xa2fb656751Bb98544b7D0b219F26dadD6138ECEA',
      },
      token: tokens.renbtc,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 81,
      lpSymbol: 'RENBTC-ALLOY',
      lpAddresses: {
        56: '0x74dbCcdF1829ef5Af15be144470eAd4944d9eF7B',
      },
      quoteToken: tokens.alloy,
      token: tokens.renbtc,
    },
    {
      pid: 82,
      lpSymbol: 'RENZEC-BNB',
      lpAddresses: {
        56: '0x54120d7cec487ae9b38379dd22232db54a9b873e',
      },

      token: tokens.renzec,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 83,
      lpSymbol: 'RENZEC-ALLOY',
      lpAddresses: {
        56: '0x13eEf3737CD674e1509f82AcbE75D666E4eb70C0',
      },

      quoteToken: tokens.alloy,
      token: tokens.renzec,
    },
    {
      pid: 84,
      lpSymbol: 'RENBCH-BNB',
      lpAddresses: {
        56: '0xFEEF2FB7BcaE375677C1FC2aEb52a0bAf7782b81',
      },

      token: tokens.renbch,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 85,
      lpSymbol: 'RENBCH-ALLOY',
      lpAddresses: {
        56: '0x11C9703170321596978233A9309efDe9179a9C28',
      },

      quoteToken: tokens.alloy,
      token: tokens.renbch,
    },
    {
      pid: 88,
      lpSymbol: 'ADA-BNB',
      lpAddresses: {
        97: '',
        56: '0x700693fbfb05ac9ee4928959591f38490711539c',
      },

      token: tokens.ada,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 89,
      lpSymbol: 'ADA-ALLOY',
      lpAddresses: {
        97: '',
        56: '0x84af8912a8dcd8023d2608e83c5536387f424036',
      },
      quoteToken: tokens.alloy,
      token: tokens.ada,
    },
    {
      pid: 90,
      lpSymbol: 'HERO-BNB',
      lpAddresses: {
        97: '',
        56: '0x52A7fA89E63F2f8e1e6EdD00E4491a55B915cdBE',
      },

      token: tokens.hero,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 91,
      lpSymbol: 'HERO-ALLOY',
      lpAddresses: {
        97: '',
        56: '0xa87ec0c5836430152545cc7c296d0f16d6abd733',
      },
      quoteToken: tokens.alloy,
      token: tokens.hero,
    },
    {
      pid: 92,
      lpSymbol: 'KROWN-BNB',
      lpAddresses: {
        97: '',
        56: '0xDF16025b38f147974e446E3C50f2afdCDdBF861f',
      },

      token: tokens.krown,
      quoteToken: tokens.wbnb,
    },
    {
      pid: 93,
      lpSymbol: 'KROWN-ALLOY',
      lpAddresses: {
        97: '',
        56: '0xf68414274BA342E9C1Bd4DAD3241640E349CAf07',
      },
      quoteToken: tokens.alloy,
      token: tokens.krown,
    },
  ],
  [Network.FANTOM]: [
    {
      pid: 0,
      lpSymbol: 'ORI',
      lpAddresses: {
        250: '0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
      },
      token: tokens.ori,
      quoteToken: tokens.wftm,
    },
    {
      pid: 1,
      lpSymbol: 'ORI-FTM',
      lpAddresses: {
        250: '0x0433305Ae161d28eD1408D11581610339dfC9BE4',
      },
      token: tokens.ori,
      quoteToken: tokens.wftm,
    },
    {
      pid: 3,
      lpSymbol: 'USDC-FTM',
      lpAddresses: {
        250: '0x50cc648e45b84d68405ba0707e94c507b08e593d',
      },
      token: tokens.usdc,
      quoteToken: tokens.wftm,
    },
    {
      pid: 2,
      lpSymbol: 'AURORA-FTM',
      lpAddresses: {
        250: '0x483d07bc99cdd1df66f64ae0a769a70725c46fa4',
      },
      token: tokens.aurora,
      quoteToken: tokens.wftm,
    },
    {
      pid: 8,
      lpSymbol: 'ORI-USDC',
      lpAddresses: {
        250: '0xF70a9Af9f581c2F752Acc779148712c43E58d2f0',
      },
      token: tokens.usdc,
      quoteToken: tokens.ori,
    },
    {
      pid: 18,
      lpSymbol: 'BNB-FTM',
      lpAddresses: {
        250: '0x55c5b2868eae98f38c8c6b84435ed90b400466f9',
      },
      token: tokens.bnb,
      quoteToken: tokens.wftm,
    },
    {
      pid: 23,
      lpSymbol: 'renBTC-FTM',
      lpAddresses: {
        250: '0x91BDC3Dc2028E53Be65f7fd84f21a4a46eA3CB4B',
      },
      token: tokens.renbtc,
      quoteToken: tokens.wftm,
    },
    {
      pid: 24,
      lpSymbol: 'renBTC-ORI',
      lpAddresses: {
        250: '0xB7A8659fe7a9741EB2082F177d102f58C86BBe87',
      },
      token: tokens.renbtc,
      quoteToken: tokens.ori,
    },
    {
      pid: 25,
      lpSymbol: 'renZEC-FTM',
      lpAddresses: {
        250: '0x6b7edb556b0428fa4fe58609052618b56ccc7c46',
      },
      token: tokens.renzec,
      quoteToken: tokens.wftm,
    },
    {
      pid: 26,
      lpSymbol: 'renZEC-ORI',
      lpAddresses: {
        250: '0x28ac64a278A51c84E0C4C65ba561148e5b15CF43',
      },
      token: tokens.renzec,
      quoteToken: tokens.ori,
    },
    {
      pid: 27,
      lpSymbol: 'renBCH-FTM',
      lpAddresses: {
        250: '0xCb90e14e3d73090C6137623225c2E155272038D2',
      },
      token: tokens.renbch,
      quoteToken: tokens.wftm,
    },
    {
      pid: 28,
      lpSymbol: 'renBCH-ORI',
      lpAddresses: {
        250: '0x9b6553e4A939DC1f56524337AE10E298B3aFb9CD',
      },
      token: tokens.renbch,
      quoteToken: tokens.ori,
    },
    {
      pid: 20,
      lpSymbol: 'ICE-FTM',
      lpAddresses: {
        250: '0x3d7bc2ee79a21e56dc52b874de3ad5c341f6d888',
      },
      token: tokens.ice,
      quoteToken: tokens.wftm,
    },
    {
      pid: 19,
      lpSymbol: 'ZOO-FTM',
      lpAddresses: {
        250: '0xe200510dc1f28b53c1b1f629861dde4f8b06b373',
      },
      token: tokens.zoo,
      quoteToken: tokens.wftm,
    },
    {
      pid: 21,
      lpSymbol: 'ESWAP-FTM',
      lpAddresses: {
        250: '0x08492d0fF546B08a9Fb3bd056665cfD240B7D55c',
      },
      token: tokens.eswap,
      quoteToken: tokens.wftm,
    },
    {
      pid: 22,
      lpSymbol: 'ESWAP-ORI',
      lpAddresses: {
        250: '0x1935776c366db172a3c4881c7548Cd35D7457c5c',
      },
      token: tokens.eswap,
      quoteToken: tokens.ori,
    },
    {
      pid: 13,
      lpSymbol: 'SUSHI-FTM',
      lpAddresses: {
        250: '0xf9f979b0283031c4afa4d668626779363b5fe510',
      },
      token: tokens.sushi,
      quoteToken: tokens.wftm,
    },
    {
      pid: 14,
      lpSymbol: 'YFI-FTM',
      lpAddresses: {
        250: '0xbd9d4981ded98f5dedfd7920c3857c4d6a5ea113',
      },
      token: tokens.yfi,
      quoteToken: tokens.wftm,
    },
    {
      pid: 15,
      lpSymbol: 'SNX-FTM',
      lpAddresses: {
        250: '0xf2fcd382ee404b105bfc0086ed41f1bd19b8952a',
      },
      token: tokens.snx,
      quoteToken: tokens.wftm,
    },
    {
      pid: 9,
      lpSymbol: 'DAI-USDC',
      lpAddresses: {
        250: '0xD7Ee3fd59a2d8082B56319f858fceaD9a902C0A1',
      },
      token: tokens.dai,
      quoteToken: tokens.usdc,
    },
    {
      pid: 7,
      lpSymbol: 'DAI-FTM',
      lpAddresses: {
        250: '0x6d898d98818e670c695e374ed77cd1753cf109dd',
      },
      token: tokens.dai,
      quoteToken: tokens.wftm,
    },
    {
      pid: 4,
      lpSymbol: 'ETH-FTM',
      lpAddresses: {
        250: '0xc81a9ce5be68df6a52f82d83398420d8a1c245c2',
      },
      token: tokens.eth,
      quoteToken: tokens.wftm,
    },
    {
      pid: 5,
      lpSymbol: 'BTC-FTM',
      lpAddresses: {
        250: '0x9a2b08b7620b8c387d96f9c1dc315afd93651f5e',
      },
      token: tokens.btc,
      quoteToken: tokens.wftm,
    },
    {
      pid: 6,
      lpSymbol: 'LINK-FTM',
      lpAddresses: {
        250: '0x442beca06141f99466d288bde60e79330ecbdf51',
      },
      token: tokens.link,
      quoteToken: tokens.wftm,
    },
    {
      pid: 10,
      lpSymbol: 'ETH-ORI',
      lpAddresses: {
        250: '0xb18E654Af9c3bd229aD72C9F1e8B67518CE6aF37',
      },
      token: tokens.eth,
      quoteToken: tokens.ori,
    },
    {
      pid: 11,
      lpSymbol: 'BTC-ORI',
      lpAddresses: {
        250: '0xdDe0ACA9Dc806544f7D5D4DD308a4ee065364Cca',
      },
      token: tokens.btc,
      quoteToken: tokens.ori,
    },
    {
      pid: 12,
      lpSymbol: 'LINK-ORI',
      lpAddresses: {
        250: '0x9571d8B11035657d6FcC26fbD26ABFa23Fb62ae2',
      },
      token: tokens.link,
      quoteToken: tokens.ori,
    },
    {
      pid: 16,
      lpSymbol: 'CZTEARS-FTM',
      lpAddresses: {
        250: '0x2c78edc5b446bec6f1efca357f089605ddd485b5',
      },
      token: tokens.cztears,
      quoteToken: tokens.wftm,
    },
    {
      pid: 29,
      lpSymbol: 'CRV-FTM',
      lpAddresses: {
        97: '',
        250: '0x7785698f2aa354558c77a0186be0dfd2a9b2452d',
      },
      token: tokens.curve,
      quoteToken: tokens.wftm,
    },
    {
      pid: 30,
      lpSymbol: 'CRV-ORI',
      lpAddresses: {
        97: '',
        250: '0x1449Eb84698D6C2faf86863faB8A91aa975ef2F8',
      },
      token: tokens.curve,
      quoteToken: tokens.ori,
    },
  ],
}

export default farms
