import { Network } from '@hyperjump-defi/sdk'
import tokens from './tokens'
import { PoolCategory, PoolConfig } from './types'

const pools: Record<Network, PoolConfig[]> = {
  [Network.BSC_TESTNET]: [
    {
      sousId: 0,
      stakingToken: tokens.jump,
      earningToken: tokens.jump,
      contractAddress: {
        97: '0x683fE36D7e543181ba19101283B7Ed3c2B3CD749',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '3.141592653589793238',
      sortOrder: 1,
      isFinished: false,
    },
  ],
  [Network.BSC]: [
    {
      sousId: 0,
      stakingToken: tokens.alloy,
      earningToken: tokens.alloy,
      contractAddress: {
        56: '0x4F1818Ff649498a2441aE1AD29ccF55a8E1C6250',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '3.141592653589793238',
      sortOrder: 1,
      isFinished: false,
    },
    {
      sousId: 111,
      stakingToken: tokens.mech,
      earningToken: tokens.bake,
      contractAddress: {
        56: '0x992584ff7857E3c3731e6a63251cEf5a245dF486',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00756944',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 110,
      stakingToken: tokens.mech,
      earningToken: tokens.krown,
      contractAddress: {
        56: '0x5020BfCF206B4143521d029FA3CFA6031549aAa4',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '8.1018',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 109,
      stakingToken: tokens.mech,
      earningToken: tokens.hero,
      contractAddress: {
        56: '0x1f6212486E8d6031dB720d368b76FC1992bF7d55',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.1852',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 108,
      stakingToken: tokens.mech,
      earningToken: tokens.cake,
      contractAddress: {
        56: '0x8b5d4FF408542E2003b1BEe8a35a97D3c7f0e46d',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00214',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 107,
      stakingToken: tokens.mech,
      earningToken: tokens.axs,
      contractAddress: {
        97: '',
        56: '0x26B9dc616905C9f382dB4417c2EF1c541Ec2b013',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0017',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 106,
      stakingToken: tokens.mech,
      earningToken: tokens.ada,
      contractAddress: {
        97: '',
        56: '0x40652E48cA5b5Ae233608739D5105dF5ABcDa042',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0248',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 105,
      stakingToken: tokens.mech,
      earningToken: tokens.dvt,
      contractAddress: {
        56: '0x9C50063F797558e12c0da038560a1Ff3C120f8D3',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '21.7014',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 104,
      stakingToken: tokens.mech,
      earningToken: tokens.keif,
      contractAddress: {
        56: '0xCE7Df55508497B30722c5ae3b73B639b5Cc11716',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '2.7778',
      isFinished: true,
    },
    {
      sousId: 103,
      stakingToken: tokens.mech,
      earningToken: tokens.wings,
      contractAddress: {
        56: '0x5c8Dcbb32d800D091F7297ae79C1687Eb9515287',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00571180',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 102,
      stakingToken: tokens.mech,
      earningToken: tokens.sphn,
      contractAddress: {
        56: '0xD41Ef2cbCBd6BdFa588E71eBB9C10c60C7FC5eC6',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0025706',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 101,
      stakingToken: tokens.mech,
      earningToken: tokens.ont,
      contractAddress: {
        56: '0x46d6c2Cf97b2777C283Bb25Fc101dAaC17820C6F',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0442803745',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 100,
      stakingToken: tokens.mech,
      earningToken: tokens.ccbd,
      contractAddress: {
        56: '0xe2957C35aF821f4e75DD23c443f68D6929F20c63',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0026',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 99,
      stakingToken: tokens.mech,
      earningToken: tokens.scbd,
      contractAddress: {
        56: '0x85A6513de4BC61e98dF8C2cEEfa9b9af78f89385',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00026',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 98,
      stakingToken: tokens.mech,
      earningToken: tokens.naut,
      contractAddress: {
        56: '0x9B2532fFE851BFC5D48139e972f207fbe5789b2C',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.006481481',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 97,
      stakingToken: tokens.mech,
      earningToken: tokens.fts,
      contractAddress: {
        56: '0xc7F6055C9294A5bF033Ae7b1c7Fb46bDacB3D14F',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00345',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 96,
      stakingToken: tokens.mech,
      earningToken: tokens.grand,
      contractAddress: {
        56: '0xE29BD1a7Ff904b9DdaaF0A81E76895dC9AdAd536',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0000625',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 95,
      stakingToken: tokens.mech,
      earningToken: tokens.xditto,
      contractAddress: {
        56: '0x10b4722629D70B2515F90C88DDe278Ff48985cf2',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.018226852',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 94,
      stakingToken: tokens.mech,
      earningToken: tokens.milk2,
      contractAddress: {
        56: '0xc11bd6c6Babd9e6279a4485E6494423D8d53230f',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.110231481',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 93,
      stakingToken: tokens.mech,
      earningToken: tokens.cake,
      contractAddress: {
        56: '0x324023F9Fc63e0C779db983BDB4C768581B732Ac',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0069',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 92,
      stakingToken: tokens.mech,
      earningToken: tokens.pmp,
      contractAddress: {
        56: '0xF83400E9CcE0837730d6BDD4B5EF7f3A5d4C0772',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00054',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 91,
      stakingToken: tokens.mech,
      earningToken: tokens.banana,
      contractAddress: {
        56: '0x23209f4390a3AD23bde41e8c82C17Fbf24c843D0',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0444',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 90,
      stakingToken: tokens.mech,
      earningToken: tokens.brn,
      contractAddress: {
        56: '0x027587C42D8c43b5e8E622aa9981A409EF625A85',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '2.083',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 89,
      stakingToken: tokens.mech,
      earningToken: tokens.watch,
      contractAddress: {
        56: '0x81A7C8A564cBd4F9552468Ce54bF3E6bCF7Ffe7e',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0347',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 88,
      stakingToken: tokens.mech,
      earningToken: tokens.xblzd,
      contractAddress: {
        56: '0xa6Eedef39fbF8D57670B40086a8A23cB03B80247',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.000975',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 87,
      stakingToken: tokens.mech,
      earningToken: tokens.treat,
      contractAddress: {
        56: '0xA4dF79Eb246150D33CCc6Dc4773D543cbEd6fDa8',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.007715',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 86,
      stakingToken: tokens.mech,
      earningToken: tokens.soul,
      contractAddress: {
        56: '0xb1160fA55Ba9E91c17C6e12cb6CD3ef1ECD3f552',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '146',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 85,
      stakingToken: tokens.mech,
      earningToken: tokens.nar,
      contractAddress: {
        56: '0x5C9C4b9412E193D38374eF954d10C74DD4f1C9d0',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.09375',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 84,
      stakingToken: tokens.mech,
      earningToken: tokens.gfce,
      contractAddress: {
        56: '0x4ADc9b5aAAfed932586A1b383F2F0f9ce3454c3a',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.005208',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 83,
      stakingToken: tokens.mech,
      earningToken: tokens.beluga,
      contractAddress: {
        56: '0xaa0B96faf1431e428223d9ffe1d9667D5F2AC451',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.000239583',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 81,
      stakingToken: tokens.mech,
      earningToken: tokens.oil,
      contractAddress: {
        56: '0x91eD56d16CA749c6EE0Ec6a5c3693A5ce479137d',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0016741',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 80,
      stakingToken: tokens.mech,
      earningToken: tokens.voodoo,
      contractAddress: {
        56: '0x0598E8180d8dF40318043fA5E349Eb40B6873c5d',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.010416667',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 79,
      stakingToken: tokens.mech,
      earningToken: tokens.brew,
      contractAddress: {
        56: '0x2fa41e8512372FeD85C101fda7700E1a2AfC0Fe4',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.004236111',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 78,
      stakingToken: tokens.mech,
      earningToken: tokens.ramen,
      contractAddress: {
        56: '0xCC20695f08871466289A65673a818728E760A965',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.006944444',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 77,
      stakingToken: tokens.mech,
      earningToken: tokens.slime,
      contractAddress: {
        56: '0xBc14A31a76b8B49a6600dA33003a6dD2A90dA2C1',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.004513889',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 76,
      stakingToken: tokens.mech,
      earningToken: tokens.hps,
      contractAddress: {
        56: '0xa389fAa5396a3ec8eAA91B874135569480366798',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.023149',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 75,
      stakingToken: tokens.mech,
      earningToken: tokens.ditto,
      contractAddress: {
        56: '0x00886Ffc995DadDd4aD888AB251bF594Fff3BAA6',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.03473',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 74,
      stakingToken: tokens.mech,
      earningToken: tokens.crow,
      contractAddress: {
        56: '0xdDC66b82BFeac163f096C5dd91faB099F382470A',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.000072917',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 73,
      stakingToken: tokens.mech,
      earningToken: tokens.btd,
      contractAddress: {
        56: '0x8498eD98E95a833CCd246C4f0e29b64352707007',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.03472',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 72,
      stakingToken: tokens.mech,
      earningToken: tokens.dvt,
      contractAddress: {
        56: '0xf4DD1c9676E0eFf4Dbd3179D28Bd84d42A0Ea7EA',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '4.20',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 82,
      stakingToken: tokens.mech,
      earningToken: tokens.innbc,
      contractAddress: {
        56: '0x6AeE755C302d8dAdBd7Fa37A91e60a2bB36f96C0',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '1.0833',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 71,
      stakingToken: tokens.mech,
      earningToken: tokens.soak,
      contractAddress: {
        56: '0x573e7021733315a866BcBb449553cE320E7285bD',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.012400794',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 70,
      stakingToken: tokens.mech,
      earningToken: tokens.supra,
      contractAddress: {
        56: '0x9Fc01bBF65b405F6aec3f3AbaE20C14517b5260A',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.000104167',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 69,
      stakingToken: tokens.mech,
      earningToken: tokens.jgn,
      contractAddress: {
        56: '0x1b04a6Cdd6422f44Eeea2dbe9bf1127A1AE82F4b',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.043402778',
      sortOrder: 999,
      isFinished: true,
    },
  ],
  [Network.FANTOM]: [
    {
      sousId: 0,
      stakingToken: tokens.ori,
      earningToken: tokens.ori,
      contractAddress: {
        250: '0x90Df158ff7c31aD1d81ddDb1D8ab9d0eCBCeDa20',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '3.141592653589793238',
      sortOrder: 1,
      isFinished: false,
    },
    {
      sousId: 102,
      stakingToken: tokens.mech,
      earningToken: tokens.scream,
      contractAddress: {
        250: '0xc65dcE8a0C6754cE57b564C8ff22481E5485b5c0',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00006262',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 101,
      stakingToken: tokens.mech,
      earningToken: tokens.btc,
      contractAddress: {
        250: '0xd29523614c36e760Eb9d78384D16BF9542d3427B',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00000014',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 100,
      stakingToken: tokens.mech,
      earningToken: tokens.boo,
      contractAddress: {
        250: '0xC655401C289259Fc105066Bf9e67f05B5e101358',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0028',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 99,
      stakingToken: tokens.mech,
      earningToken: tokens.curve,
      contractAddress: {
        97: '',
        250: '0xE090863F733C9a2E2dDE0869bD9489DB4F4AedF1',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.006',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 98,
      stakingToken: tokens.mech,
      earningToken: tokens.usdc,
      contractAddress: {
        97: '',
        250: '0xcEeb0716D49f712009A00A30120E7B754FdC7eFB',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0347',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 97,
      stakingToken: tokens.mech,
      earningToken: tokens.cztears,
      contractAddress: {
        250: '0x30B6578fB575d881374b9DD956d0B53e54594086',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.65799',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 96,
      stakingToken: tokens.mech,
      earningToken: tokens.btc,
      contractAddress: {
        250: '0x630a3d5341821c00EAFC64101d99E62606FA0E21',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00000115',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 95,
      stakingToken: tokens.mech,
      earningToken: tokens.eth,
      contractAddress: {
        250: '0x678Ac451679F268E3a906dbb35409150154780a6',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0000093',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 94,
      stakingToken: tokens.mech,
      earningToken: tokens.aurora,
      contractAddress: {
        250: '0x57798acFdC584721EDc761C698fe26F90B59eA3d',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.115740741',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 93,
      stakingToken: tokens.mech,
      earningToken: tokens.link,
      contractAddress: {
        250: '0xDd5082d3bbf42d9606b663777C9Bf6d0f0f1222E',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0012',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 92,
      stakingToken: tokens.mech,
      earningToken: tokens.eswap,
      contractAddress: {
        250: '0x74B6E8AC0D21c6298DD9084e1E5C401B13efEce1',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.03038194',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 91,
      stakingToken: tokens.mech,
      earningToken: tokens.eswap,
      contractAddress: {
        250: '0x95804C1d46BDb58E4FE09A9472da117cc13E6251',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.030375',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 90,
      stakingToken: tokens.mech,
      earningToken: tokens.eswap,
      contractAddress: {
        250: '0x735267b12D8fd39015db7B62e75bFBF356b65b40',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.030375',
      sortOrder: 999,
      isFinished: true,
    },
    {
      sousId: 89,
      stakingToken: tokens.mech,
      earningToken: tokens.eswap,
      contractAddress: {
        250: '0xf061f0b017f549D972caB6E11448ec0b5C6d40CD',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0101273',
      sortOrder: 999,
      isFinished: true,
    },
  ],
}

export default pools
