import { Network } from '@hyperjump-defi/sdk'
import { VaultConfig } from './types'

const vaults: Record<Network, VaultConfig[]> = {
  [Network.BSC_TESTNET]: [],
  [Network.BSC]: [
    {
      id: 'hyperjump-alloy-wbnb',
      logo: 'hyper/alloy-bnb.svg',
      name: 'ALLOY-BNB LP',
      token: 'ALLOY-BNB LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xf0696E201D20b553792ac2578429B791625308A0',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyBnb',
      earnedTokenAddress: '0x8B0F9f37fB0A84BB2964dcA51ba20a6a2496801f',
      earnContractAddress: '0x8B0F9f37fB0A84BB2964dcA51ba20a6a2496801f',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-wbnb',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'BNB'],
      callFee: 0.5,
      addLiquidityUrl: '/add/BNB/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-busd',
      logo: 'hyper/alloy-busd.svg',
      name: 'ALLOY-BUSD LP',
      token: 'ALLOY-BUSD LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x6e94fb9c08f7e651bf0ed732708e5f148db061c6',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyBusd',
      earnedTokenAddress: '0x517ecB1BD66ca8853c4DB671D660A62d011bA913',
      earnContractAddress: '0x517ecB1BD66ca8853c4DB671D660A62d011bA913',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-busd',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'BUSD'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xe9e7cea3dedca5984780bafc599bd69add087d56/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-cake',
      logo: 'hyper/cake-alloy.svg',
      name: 'ALLOY-CAKE LP',
      token: 'ALLOY-CAKE LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x520b5627235e70747c33cd36d43690c5bb20b999',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyCake',
      earnedTokenAddress: '0x4Dd01687329c05Ac9Db46fefc5C6482e7175B378',
      earnContractAddress: '0x4Dd01687329c05Ac9Db46fefc5C6482e7175B378',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-cake',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'CAKE'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-dvt-alloy',
      logo: 'hyper/dvt-alloy.svg',
      name: 'DVT-ALLOY LP',
      token: 'DVT-ALLOY LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xc3CB64CBCA0B0c38F14aaA0b44E9FBb5B2D67E82',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpDvtAlloy',
      earnedTokenAddress: '0xdc0524CFdf95D8F6Cbf27558149737428a5227Ac',
      earnContractAddress: '0xdc0524CFdf95D8F6Cbf27558149737428a5227Ac',
      oracle: 'lps',
      oracleId: 'hyperjump-dvt-alloy',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'DVT'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-ont-wbnb',
      logo: 'hyper/ont-bnb.svg',
      name: 'ONT-BNB LP',
      token: 'ONT-BNB LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x8d0008E53d3843Fae0f1249Fa26d91aB4902bF5e',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpontbnb',
      earnedTokenAddress: '0xfF567618C4698268aBd0c02E0a991a77d6379481',
      earnContractAddress: '0xfF567618C4698268aBd0c02E0a991a77d6379481',
      oracle: 'lps',
      oracleId: 'hyperjump-ont-wbnb',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ONT', 'BNB'],
      callFee: 0.5,
      addLiquidityUrl: '/add/BNB/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
      buyTokenUrl: '/swap?outputCurrency=0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
    },
    {
      id: 'hyperjump-ont-alloy',
      logo: 'hyper/ont-bnb.svg',
      name: 'ALLOY-ONT LP',
      token: 'ALLOY-ONT LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x5B8Ba3E399b06e09a203094A3B3227d2dAfa4FbA',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpontalloy',
      earnedTokenAddress: '0xC99F114Fab61c523f9b20f41DAb908cA810cFE2A',
      earnContractAddress: '0xC99F114Fab61c523f9b20f41DAb908cA810cFE2A',
      oracle: 'lps',
      oracleId: 'hyperjump-ont-alloy',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ONT', 'ALLOY'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xe9e7cea3dedca5984780bafc599bd69add087d56/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
      buyTokenUrl: '/swap?outputCurrency=0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
    },
    {
      id: 'hyperjump-alloy-watch',
      logo: 'hyper/watch-alloy.svg',
      name: 'ALLOY-WATCH LP',
      token: 'ALLOY-WATCH LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x45a60fbb8f531e707477bb53287a1691c5383ef7',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyWatch',
      earnedTokenAddress: '0xddD64f470D3BF5125601FFcB008731BDF810c12d',
      earnContractAddress: '0xddD64f470D3BF5125601FFcB008731BDF810c12d',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-watch',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'WATCH'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-xblzd',
      logo: 'hyper/xblzd-alloy.svg',
      name: 'ALLOY-xBLZD LP',
      token: 'ALLOY-xBLZD LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xbe4b54b0f1233088a89466525d574413f6eaf87d',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyXblzd',
      earnedTokenAddress: '0x34FE37CA58d9814179F548BA39de485FcAB3633E',
      earnContractAddress: '0x34FE37CA58d9814179F548BA39de485FcAB3633E',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-xblzd',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'xBLZD'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x9a946c3cb16c08334b69ae249690c236ebd5583e/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-treat',
      logo: 'hyper/treat-alloy.svg',
      name: 'ALLOY-TREAT LP',
      token: 'ALLOY-TREAT LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xa532d578f81a9144bc5598c5db31490c0d9b1460',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyTreat',
      earnedTokenAddress: '0x0Fa9420A4BFc08dBb6BDd1728B1555a96c59Fa8d',
      earnContractAddress: '0x0Fa9420A4BFc08dBb6BDd1728B1555a96c59Fa8d',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-treat',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'TREAT'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xac0c7d9b063ed2c0946982ddb378e03886c064e6/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-soul',
      logo: 'hyper/soul-alloy.svg',
      name: 'ALLOY-SOUL LP',
      token: 'ALLOY-SOUL LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xafa6304a362085ce99a8986893a2c41b24668e79',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloySoul',
      earnedTokenAddress: '0xF5666B63aA51D96D1d8B6eB893Ef54a8cB7Ec019',
      earnContractAddress: '0xF5666B63aA51D96D1d8B6eB893Ef54a8cB7Ec019',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-soul',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'SOUL'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x67d012f731c23f0313cea1186d0121779c77fcfe/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-jgn',
      logo: 'hyper/jgn-alloy.svg',
      name: 'ALLOY-JGN LP',
      token: 'ALLOY-JGN LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x5f4276e7e4c34fba1cfe4145d1c0417c8d223a81',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyJgn',
      earnedTokenAddress: '0xd7Da1FE9fE5C339401640652374bd635313aa055',
      earnContractAddress: '0xd7Da1FE9fE5C339401640652374bd635313aa055',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-jgn',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'JGN'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-soak',
      logo: 'hyper/soak-alloy.svg',
      name: 'ALLOY-SOAK LP',
      token: 'ALLOY-SOAK LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x911439fc0438924c2a56e7a849dd018150eed1ab',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloySoak',
      earnedTokenAddress: '0xDCF2e3c93802dc1EfD958CC3E423d48419456CfA',
      earnContractAddress: '0xDCF2e3c93802dc1EfD958CC3E423d48419456CfA',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-soak',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'SOAK'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x849233ff1aea15d80ef658b2871664c9ca994063/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-gfce',
      logo: 'hyper/gfce-alloy.svg',
      name: 'ALLOY-GFCE LP',
      token: 'ALLOY-GFCE LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x5bc1540f6984020100b68b9ca5dcdf53771d28cb',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyGfce',
      earnedTokenAddress: '0xd88969070E9fDa22d47c02a41154aF52573aD881',
      earnContractAddress: '0xd88969070E9fDa22d47c02a41154aF52573aD881',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-gfce',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'GFCE'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x94babbe728d9411612ee41b20241a6fa251b26ce/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-beluga-alloy',
      logo: 'hyper/beluga-alloy.svg',
      name: 'ALLOY-BELUGA LP',
      token: 'ALLOY-BELUGA LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x208063655593ebe16384881b073ad38282d794a0',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpAlloyBeluga',
      earnedTokenAddress: '0x471Ff10A810ce5a367042B55C56753Cb1E385410',
      earnContractAddress: '0x471Ff10A810ce5a367042B55C56753Cb1E385410',
      oracle: 'lps',
      oracleId: 'hyperjump-beluga-alloy',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'BELUGA'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x181de8c57c4f25eba9fd27757bbd11cc66a55d31/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-ccbd-alloy',
      logo: 'hyper/ccbd-alloy.svg',
      name: 'ALLOY-cCBD LP',
      token: 'ALLOY-cCBD LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x45c852f282bbce4401de927aba6e620dc5b535f3',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpCcbdAlloy',
      earnedTokenAddress: '0x830eB6B1c8476ef491C703d326b86c918eEB2Ad5',
      earnContractAddress: '0x830eB6B1c8476ef491C703d326b86c918eEB2Ad5',
      oracle: 'lps',
      oracleId: 'hyperjump-ccbd-alloy',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'cCBD'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x131ded61462cb0f8034656d9294f79247f72ad0b/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-alloy-scbd',
      logo: 'hyper/scbd-alloy.svg',
      name: 'ALLOY-sCBD LP',
      token: 'ALLOY-sCBD LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x2d86015396b15df7ebd65d507b9fbe2dab088673',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpScbdAlloy',
      earnedTokenAddress: '0x297dedE79b249eDF27d1fec1f1E5B64a78b569EB',
      earnContractAddress: '0x297dedE79b249eDF27d1fec1f1E5B64a78b569EB',
      oracle: 'lps',
      oracleId: 'hyperjump-alloy-scbd',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'sCBD'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0x7a0ffa3db812701e711fc38eacb99675352f31be/0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
      buyTokenUrl: '/swap?outputCurrency=0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    },
    {
      id: 'hyperjump-ditto-wbnb',
      logo: 'hyper/ditto-bnb.svg',
      name: 'DITTO-BNB LP',
      token: 'DITTO-BNB LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x99ce2ec80940d03a30898421654a038e75ab54a8',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpDittoBnb',
      earnedTokenAddress: '0xaC95A725de420528901E600E34AC6d447F571f71',
      earnContractAddress: '0xaC95A725de420528901E600E34AC6d447F571f71',
      oracle: 'lps',
      oracleId: 'hyperjump-ditto-wbnb',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['DITTO', 'BNB'],
      callFee: 0.5,
      addLiquidityUrl: '/add/BNB/0x233d91a0713155003fc4dce0afa871b508b3b715',
      buyTokenUrl: '/swap?outputCurrency=0x233d91a0713155003fc4dce0afa871b508b3b715',
    },
    {
      id: 'hyperjump-wbnb-busd',
      logo: 'hyper/busd-bnb.svg',
      name: 'BUSD-BNB LP',
      token: 'BUSD-BNB LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xf2e4e3f9b58b3edac88ad11d689a23f3119a782d',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpBusdBnb',
      earnedTokenAddress: '0x7aA0E23Ebf9fd342f3F4b1Eb62636f74cA3D2825',
      earnContractAddress: '0x7aA0E23Ebf9fd342f3F4b1Eb62636f74cA3D2825',
      oracle: 'lps',
      oracleId: 'hyperjump-wbnb-busd',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['BNB', 'BUSD'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xe9e7cea3dedca5984780bafc599bd69add087d56/BNB',
      buyTokenUrl: '/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    {
      id: 'hyperjump-sphn-alloy',
      logo: 'hyper/sphn-alloy.svg',
      name: 'SPHN-ALLOY LP',
      token: 'SPHN-ALLOY LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x34E676f74fe9118F50E07d9DeCa4AF96f8785397',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperjumpSphnAlloy',
      earnedTokenAddress: '0x96A5ba4Ec666d685C96698CEF2B5F13a81Af2882',
      earnContractAddress: '0x96A5ba4Ec666d685C96698CEF2B5F13a81Af2882',
      oracle: 'lps',
      oracleId: 'hyperjump-sphn-alloy',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['ALLOY', 'SPHN'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xb58a579e8f987b52564A5fE08Fe5181dc2621a9c/0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd',
      buyTokenUrl: '/swap?outputCurrency=0xb58a579e8f987b52564A5fE08Fe5181dc2621a9c',
    },
    {
      id: 'hyperjump-sphn-wbnb',
      logo: 'hyper/sphn-bnb.svg',
      name: 'SPHN-BNB LP',
      token: 'SPHN-BNB LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x402E6B9F9A2959d6f4eb398071d90270a6583C73',
      tokenDecimals: 18,
      tokenDescriptionUrl: '#',
      earnedToken: 'hyperJumpSphnBnb',
      earnedTokenAddress: '0x64776D5BdbD391716a01c9FfF35Fc18FBCE01447',
      earnContractAddress: '0x64776D5BdbD391716a01c9FfF35Fc18FBCE01447',
      oracle: 'lps',
      oracleId: 'hyperjump-sphn-wbnb',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'Hyperjump',
      assets: ['BNB', 'SPHN'],
      callFee: 0.5,
      addLiquidityUrl: '/add//0xb58a579e8f987b52564A5fE08Fe5181dc2621a9c/BNB',
      buyTokenUrl: '/swap?outputCurrency=0xb58a579e8f987b52564A5fE08Fe5181dc2621a9c',
    },
  ],
  [Network.FANTOM]: [
    {
      id: 'hyperjump-wftm-ori',
      logo: 'hyper/ori-ftm.svg',
      name: 'ORI-FTM LP',
      token: 'ORI-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x0433305Ae161d28eD1408D11581610339dfC9BE4',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpOriFtm',
      earnedTokenAddress: '0x6C89FEC29e95d187A35915BC18a942C4000A6FE9',
      earnContractAddress: '0x6C89FEC29e95d187A35915BC18a942C4000A6FE9',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-ori',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ORI', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
      buyTokenUrl: '/swap?outputCurrency=0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
    },
    {
      id: 'hyperjump-wftm-aurora',
      logo: 'hyper/aurora-ftm.svg',
      name: 'AURORA-FTM LP',
      token: 'AURORA-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x483d07bc99cdd1df66f64ae0a769a70725c46fa4',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpAuroraFtm',
      earnedTokenAddress: '0x551bdA71Ae8A3d03915F788C4467d8970214b2e8',
      earnContractAddress: '0x551bdA71Ae8A3d03915F788C4467d8970214b2e8',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-aurora',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['AURORA', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0xbc2451AaD349b6B43FD05F4F0cC327F8A6bcA2d4',
      buyTokenUrl: '/swap?outputCurrency=0xbc2451AaD349b6B43FD05F4F0cC327F8A6bcA2d4',
    },
    {
      id: 'hyperjump-ori-usdc',
      logo: 'hyper/ori-usdc.svg',
      name: 'ORI-USDC LP',
      token: 'ORI-USDC LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xF70a9Af9f581c2F752Acc779148712c43E58d2f0',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpOriUsdc',
      earnedTokenAddress: '0xd182CaaaB6C40cd3f8a1cC77202F21C184d2fC00',
      earnContractAddress: '0xd182CaaaB6C40cd3f8a1cC77202F21C184d2fC00',
      oracle: 'lps',
      oracleId: 'hyperjump-ori-usdc',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ORI', 'USDC'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
      buyTokenUrl: '/swap?outputCurrency=0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
    },
    {
      id: 'hyperjump-wftm-usdc',
      logo: 'hyper/usdc-ftm.svg',
      name: 'USDC-FTM LP',
      token: 'USDC-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x50cc648e45b84d68405ba0707e94c507b08e593d',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpUsdcFtm',
      earnedTokenAddress: '0x0bf472066bfb8c322Eb973Ab23d02b50cB2Ce56E',
      earnContractAddress: '0x0bf472066bfb8c322Eb973Ab23d02b50cB2Ce56E',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-usdc',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['USDC', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      buyTokenUrl: '/swap?outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    },
    {
      id: 'hyperjump-dai-usdc',
      logo: 'hyper/dai-usdc.svg',
      name: 'DAI-USDC LP',
      token: 'DAI-USDC LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xD7Ee3fd59a2d8082B56319f858fceaD9a902C0A1',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpDaiUsdc',
      earnedTokenAddress: '0x1bEaC356437A1f7e0B348729B58559a1448ff090',
      earnContractAddress: '0x1bEaC356437A1f7e0B348729B58559a1448ff090',

      oracle: 'lps',
      oracleId: 'hyperjump-dai-usdc',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['DAI', 'USDC'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x04068da6c83afcfa0e13ba15a6696662335d5b75/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
      buyTokenUrl: '/swap?outputCurrency=0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    },
    {
      id: 'hyperjump-wftm-dai',
      logo: 'hyper/dai-ftm.svg',
      name: 'DAI-FTM LP',
      token: 'DAI-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x6d898d98818e670c695e374ed77cd1753cf109dd',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpDaiFtm',
      earnedTokenAddress: '0x489d582362a6c7C8929E5807d1fE8DB1eFa8a7C0',
      earnContractAddress: '0x489d582362a6c7C8929E5807d1fE8DB1eFa8a7C0',

      oracle: 'lps',
      oracleId: 'hyperjump-wftm-dai',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['DAI', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
      buyTokenUrl: '/swap?outputCurrency=0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
    },
    {
      id: 'hyperjump-wftm-btc',
      logo: 'hyper/btc-ftm.svg',
      name: 'BTC-FTM LP',
      token: 'BTC-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x9a2b08b7620b8c387d96f9c1dc315afd93651f5e',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpBtcFtm',
      earnedTokenAddress: '0x35450dD29D4F0cA093AD5418B1cbBeD41D7322D2',
      earnContractAddress: '0x35450dD29D4F0cA093AD5418B1cbBeD41D7322D2',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-btc',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['BTC', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x321162cd933e2be498cd2267a90534a804051b11',
      buyTokenUrl: '/swap?outputCurrency=0x321162cd933e2be498cd2267a90534a804051b11',
    },
    {
      id: 'hyperjump-wftm-link',
      logo: 'hyper/link-ftm.svg',
      name: 'LINK-FTM LP',
      token: 'LINK-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x442beca06141f99466d288bde60e79330ecbdf51',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpLinkFtm',
      earnedTokenAddress: '0x270e69Ea555bC28f04d10f6f9D78cd9A907f02cF',
      earnContractAddress: '0x270e69Ea555bC28f04d10f6f9D78cd9A907f02cF',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-link',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['LINK', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
      buyTokenUrl: '/swap?outputCurrency=0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
    },
    {
      id: 'hyperjump-eswap-ori',
      logo: 'hyper/eswap-ori.svg',
      name: 'ESWAP-ORI LP',
      token: 'ESWAP-ORI LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x1935776c366db172a3c4881c7548Cd35D7457c5c',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpEswapOri',
      earnedTokenAddress: '0x80C3aCf4B37F2b28eeE311681cae8EeD05df858b',
      earnContractAddress: '0x80C3aCf4B37F2b28eeE311681cae8EeD05df858b',
      oracle: 'lps',
      oracleId: 'hyperjump-eswap-ori',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ESWAP', 'ORI'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x735aBE48e8782948a37C7765ECb76b98CdE97B0F/0x0575f8738EFdA7F512e3654F277C77e80C7d2725',
      buyTokenUrl: '/swap?outputCurrency=0x735aBE48e8782948a37C7765ECb76b98CdE97B0F',
    },
    {
      id: 'hyperjump-eswap-wftm',
      logo: 'hyper/eswap-ftm.svg',
      name: 'ESWAP-FTM LP',
      token: 'ESWAP-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x08492d0fF546B08a9Fb3bd056665cfD240B7D55c',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpEswapFtm',
      earnedTokenAddress: '0xf9c56e9BD8735844569B9086eda59BD0f10fefBa',
      earnContractAddress: '0xf9c56e9BD8735844569B9086eda59BD0f10fefBa',
      oracle: 'lps',
      oracleId: 'hyperjump-eswap-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ESWAP', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x735aBE48e8782948a37C7765ECb76b98CdE97B0F',
      buyTokenUrl: '/swap?outputCurrency=0x735aBE48e8782948a37C7765ECb76b98CdE97B0F',
    },
    {
      id: 'hyperjump-wftm-sushi',
      logo: 'hyper/sushi-ftm.svg',
      name: 'SUSHI-FTM LP',
      token: 'SUSHI-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xf9f979b0283031c4afa4d668626779363b5fe510',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpSushiFtm',
      earnedTokenAddress: '0x391825b6F0DB0D79ecadc8D6613Ab579f929109C',
      earnContractAddress: '0x391825b6F0DB0D79ecadc8D6613Ab579f929109C',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-sushi',
      depositsPaused: false,
      oraclePrice: 0,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['SUSHI', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
      buyTokenUrl: '/swap?outputCurrency=0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc',
    },
    {
      id: 'hyperjump-wftm-yfi',
      logo: 'hyper/yfi-ftm.svg',
      name: 'YFI-FTM LP',
      token: 'YFI-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xbd9d4981ded98f5dedfd7920c3857c4d6a5ea113',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpYfiFtm',
      earnedTokenAddress: '0x70462fcDdACfb1634BAF48b97e9D8D138fB3f3F1',
      earnContractAddress: '0x70462fcDdACfb1634BAF48b97e9D8D138fB3f3F1',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-yfi',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['YFI', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x29b0da86e484e1c0029b56e817912d778ac0ec69',
      buyTokenUrl: '/swap?outputCurrency=0x29b0da86e484e1c0029b56e817912d778ac0ec69',
    },
    {
      id: 'hyperjump-wftm-snx',
      logo: 'hyper/snx-ftm.svg',
      name: 'SNX-FTM LP',
      token: 'SNX-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xf2fcd382ee404b105bfc0086ed41f1bd19b8952a',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpFtmSnx',
      earnedTokenAddress: '0x14f3930F58aA580684De7216e2A49cecA2176B40',
      earnContractAddress: '0x14f3930F58aA580684De7216e2A49cecA2176B40', // this vault adddy
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-snx',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['SNX', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: '/add/FTM/0x56ee926bd8c72b2d5fa1af4d9e4cbb515a1e3adc',
      buyTokenUrl: '/swap?outputCurrency=0x56ee926bd8c72b2d5fa1af4d9e4cbb515a1e3adc',
    },
    {
      id: 'hyperjump-wftm-eth',
      logo: 'hyper/eth-ftm.svg',
      name: 'ETH-FTM LP',
      token: 'ETH-FTM LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xc81a9ce5be68df6a52f82d83398420d8a1c245c2',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpEthFtm',
      earnedTokenAddress: '0x49757216DCD8849Cb4d01919FDf46B654a95FAe5',
      earnContractAddress: '0x49757216DCD8849Cb4d01919FDf46B654a95FAe5',
      oracle: 'lps',
      oracleId: 'hyperjump-wftm-eth',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['FTM', 'ETH'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x0575f8738efda7f512e3654f277c77e80c7d2725/0x74b23882a30290451A17c44f4F05243b6b58C76d',
      buyTokenUrl: '/swap?outputCurrency=0x0575f8738efda7f512e3654f277c77e80c7d2725',
    },
    {
      id: 'hyperjump-ori-eth',
      logo: 'hyper/eth-ori.svg',
      name: 'ETH-ORI LP',
      token: 'ETH-ORI LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xb18E654Af9c3bd229aD72C9F1e8B67518CE6aF37',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpOriEth',
      earnedTokenAddress: '0xFBF20154d6A72eFAd249fb1D4038dd761C6DA5E0',
      earnContractAddress: '0xFBF20154d6A72eFAd249fb1D4038dd761C6DA5E0',
      oracle: 'lps',
      oracleId: 'hyperjump-ori-eth',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ORI', 'ETH'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x0575f8738efda7f512e3654f277c77e80c7d2725/0x74b23882a30290451A17c44f4F05243b6b58C76d',
      buyTokenUrl: '/swap?outputCurrency=0x0575f8738efda7f512e3654f277c77e80c7d2725',
    },
    {
      id: 'hyperjump-ori-btc',
      logo: 'hyper/btc-ori.svg',
      name: 'BTC-ORI LP',
      token: 'BTC-ORI LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0xdDe0ACA9Dc806544f7D5D4DD308a4ee065364Cca',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpOriBtc',
      earnedTokenAddress: '0x2e902aB1bB395f33D6Cde9862826c2ED9B5f80ac',
      earnContractAddress: '0x2e902aB1bB395f33D6Cde9862826c2ED9B5f80ac',
      oracle: 'lps',
      oracleId: 'hyperjump-ori-btc',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ORI', 'BTC'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x0575f8738efda7f512e3654f277c77e80c7d2725/0x321162Cd933E2Be498Cd2267a90534A804051b11',
      buyTokenUrl: '/swap?outputCurrency=0x0575f8738efda7f512e3654f277c77e80c7d2725',
    },
    {
      id: 'hyperjump-ori-link',
      logo: 'hyper/link-ori.svg',
      name: 'LINK-ORI LP',
      token: 'LINK-ORI LP',
      tokenDescription: 'HyperJump',
      tokenAddress: '0x9571d8B11035657d6FcC26fbD26ABFa23Fb62ae2',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpLinkOri',
      earnedTokenAddress: '0x6478C1cbAE4CD36f5a5DA1Fa064ddf797820F3Bd',
      earnContractAddress: '0x6478C1cbAE4CD36f5a5DA1Fa064ddf797820F3Bd',
      oracle: 'lps',
      oracleId: 'hyperjump-ori-link',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'Hyperjump',
      assets: ['ORI', 'LINK'],
      callFee: 0.5,
      addLiquidityUrl: '/add/0x0575f8738efda7f512e3654f277c77e80c7d2725/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8',
      buyTokenUrl: '/swap?outputCurrency=0x0575f8738efda7f512e3654f277c77e80c7d2725',
    },
    {
      id: 'spooky-wftm-boo',
      logo: 'hyper/boo-ftm.svg',
      name: 'BOO-FTM LP',
      token: 'BOO-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0xEc7178F4C41f346b2721907F5cF7628E388A7a58',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyBooFtm',
      earnedTokenAddress: '0x78EfEE7D5027Ca75b7d0c9af00aa621F24c80F6A',
      earnContractAddress: '0x78EfEE7D5027Ca75b7d0c9af00aa621F24c80F6A',
      oracle: 'lps',
      oracleId: 'spooky-wftm-boo',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'SpookySwap',
      assets: ['BOO', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
    },
    {
      id: 'spooky-usdc-wftm',
      logo: 'hyper/usdc-ftm.svg',
      name: 'USDC-FTM LP',
      token: 'USDC-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyUsdcFtm',
      earnedTokenAddress: '0x37d86Be4DBCE500F1990C11591aF3Bb1D86D7ABf',
      earnContractAddress: '0x37d86Be4DBCE500F1990C11591aF3Bb1D86D7ABf',
      oracle: 'lps',
      oracleId: 'spooky-usdc-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['USDC', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    },
    {
      id: 'spooky-aave-wftm',
      logo: 'hyper/aave-ftm.svg',
      name: 'AAVE-FTM LP',
      token: 'AAVE-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0xeBF374bB21D83Cf010cC7363918776aDF6FF2BF6',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyAaveFtm',
      earnedTokenAddress: '0x050e77D7f1aD1D72a59b95FbD8300D0B60a2eB4a',
      earnContractAddress: '0x050e77D7f1aD1D72a59b95FbD8300D0B60a2eB4a',
      oracle: 'lps',
      oracleId: 'spooky-aave-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['AAVE', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
    },
    {
      id: 'spooky-crv-wftm',
      logo: 'hyper/crv-ftm.svg',
      name: 'CRV-FTM LP',
      token: 'CRV-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0xB471Ac6eF617e952b84C6a9fF5de65A9da96C93B',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyCrvFtm',
      earnedTokenAddress: '0x502D460802030E3e2Ff98aBAa5D16AD3A03876eb',
      earnContractAddress: '0x502D460802030E3e2Ff98aBAa5D16AD3A03876eb',
      oracle: 'lps',
      oracleId: 'spooky-crv-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['CRV', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0x1E4F97b9f9F913c46F1632781732927B9019C68b',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    },
    {
      id: 'spooky-any-wftm',
      logo: 'hyper/any-ftm.svg',
      name: 'ANY-FTM LP',
      token: 'ANY-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x5c021d9cfad40aafc57786b409a9ce571de375b4',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyAnyFtm',
      earnedTokenAddress: '0xDf426c3ADC76170939c380A8f021b2eBe3cCc17E',
      earnContractAddress: '0xDf426c3ADC76170939c380A8f021b2eBe3cCc17E',
      oracle: 'lps',
      oracleId: 'spooky-any-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['ANY', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0xddcb3ffd12750b45d32e084887fdf1aabab34239',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0xddcb3ffd12750b45d32e084887fdf1aabab34239',
    },
    {
      id: 'spooky-wftm-bnb',
      logo: 'hyper/bnb-ftm.svg',
      name: 'BNB-FTM LP',
      token: 'BNB-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x956DE13EA0FA5b577E4097Be837BF4aC80005820',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyBnbFtm',
      earnedTokenAddress: '0x3138e216a5Bd0a6ccD9fC3192FBaF4e9239AB37e',
      earnContractAddress: '0x3138e216a5Bd0a6ccD9fC3192FBaF4e9239AB37e',
      oracle: 'lps',
      oracleId: 'spooky-wftm-bnb',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'SpookySwap',
      assets: ['BNB', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    },
    {
      id: 'spooky-wftm-fusdt',
      logo: 'hyper/usdt-ftm.svg',
      name: 'USDT-FTM LP',
      token: 'USDT-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x5965E53aa80a0bcF1CD6dbDd72e6A9b2AA047410',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyUsdtFtm',
      earnedTokenAddress: '0x321E6836DE57f0c9F2619a3702F2bF769D9A640c',
      earnContractAddress: '0x321E6836DE57f0c9F2619a3702F2bF769D9A640c',
      oracle: 'lps',
      oracleId: 'spooky-wftm-fusdt',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'SpookySwap',
      assets: ['fUSDT', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0x049d68029688eabf473097a2fc38ef61633a3c7a',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0x049d68029688eabf473097a2fc38ef61633a3c7a',
    },
    {
      id: 'spooky-wftm-ice',
      logo: 'hyper/ice-ftm.svg',
      name: 'ICE-FTM LP',
      token: 'ICE-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x623EE4a7F290d11C11315994dB70FB148b13021d',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyIceFtm',
      earnedTokenAddress: '0xef1E062C0687f518Dd9F74c1A7B98cB3E1936552',
      earnContractAddress: '0xef1E062C0687f518Dd9F74c1A7B98cB3E1936552',
      oracle: 'lps',
      oracleId: 'spooky-wftm-ice',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['ICE', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0xf16e81dce15b08f326220742020379b855b87df9',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0xf16e81dce15b08f326220742020379b855b87df9',
    },
    {
      id: 'spooky-wftm-cover',
      logo: 'hyper/cover-ftm.svg',
      name: 'COVER-FTM LP',
      token: 'COVER-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x5DC7848bF215F1D99F2AF3d2Bf78fcdf238EE34b',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyCoverFtm',
      earnedTokenAddress: '0xE7C0619876C90C9Aa7Af8987100C1446C7862D08',
      earnContractAddress: '0xE7C0619876C90C9Aa7Af8987100C1446C7862D08',
      oracle: 'lps',
      oracleId: 'spooky-wftm-cover',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['COVER', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    },
    {
      id: 'spooky-wftm-band',
      logo: 'hyper/band-ftm.svg',
      name: 'BAND-FTM LP',
      token: 'BAND-FTM LP',
      tokenDescription: 'SpookySwap',
      tokenAddress: '0x91b39D5584e2A7DC829f696235742cc293F2e8cf',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpookyBandFtm',
      earnedTokenAddress: '0xEED6279b15CF4f909AE1eA52626DBf39A7ea51E3',
      earnContractAddress: '0xEED6279b15CF4f909AE1eA52626DBf39A7ea51E3',
      oracle: 'lps',
      oracleId: 'spooky-wftm-band',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpookySwap',
      assets: ['BAND', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://spookyswap.finance/add/FTM/0x46E7628E8b4350b2716ab470eE0bA1fa9e76c6C5',
      buyTokenUrl: 'https://spookyswap.finance/swap?outputCurrency=0x46E7628E8b4350b2716ab470eE0bA1fa9e76c6C5',
    },
    {
      id: 'spirit-spirit-wftm',
      logo: 'hyper/spirit-ftm.svg',
      name: 'SPIRIT-FTM LP',
      token: 'SPIRIT-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x30748322B6E34545DBe0788C421886AEB5297789',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritSpiritFtm',
      earnedTokenAddress: '0x2388512d0286d49aEDd987eA6D77edA5C020fC2D',
      earnContractAddress: '0x2388512d0286d49aEDd987eA6D77edA5C020fC2D',
      oracle: 'lps',
      oracleId: 'spirit-spirit-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['SPIRIT', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x5cc61a78f164885776aa610fb0fe1257df78e59b',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x5cc61a78f164885776aa610fb0fe1257df78e59b',
    },
    {
      id: 'spirit-btc-wftm',
      logo: 'hyper/btc-ftm.svg',
      name: 'BTC-FTM LP',
      token: 'BTC-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x279b2c897737a50405ED2091694F225D83F2D3bA',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritBtcFtm',
      earnedTokenAddress: '0xD770551E9377F78DF08d71c129Ff168a46Ebf3F2',
      earnContractAddress: '0xD770551E9377F78DF08d71c129Ff168a46Ebf3F2',
      oracle: 'lps',
      oracleId: 'spirit-btc-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['BTC', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x321162Cd933E2Be498Cd2267a90534A804051b11',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    },
    {
      id: 'spirit-eth-wftm',
      logo: 'hyper/eth-ftm.svg',
      name: 'ETH-FTM LP',
      token: 'ETH-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritEthFtm',
      earnedTokenAddress: '0x6F4713307fbF7666510E5CE437d30573ea878B52',
      earnContractAddress: '0x6F4713307fbF7666510E5CE437d30573ea878B52',
      oracle: 'lps',
      oracleId: 'spirit-eth-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['ETH', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x74b23882a30290451A17c44f4F05243b6b58C76d',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    {
      id: 'spirit-usdc-wftm',
      logo: 'hyper/usdc-ftm.svg',
      name: 'USDC-FTM LP',
      token: 'USDC-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0xe7E90f5a767406efF87Fdad7EB07ef407922EC1D',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritUsdcFtm',
      earnedTokenAddress: '0xA7656dCfCaB6761d2B0D8346580962d1953cF1Ac',
      earnContractAddress: '0xA7656dCfCaB6761d2B0D8346580962d1953cF1Ac',
      oracle: 'lps',
      oracleId: 'spirit-usdc-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['USDC', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    },
    {
      id: 'spirit-bnb-wftm',
      logo: 'hyper/bnb-ftm.svg',
      name: 'BNB-FTM LP',
      token: 'BNB-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x74fE5Ddc4c27F91a1898ccd5Ac62dFeb2d3dF726',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritBnbFtm',
      earnedTokenAddress: '0xf856D98e7B380e939e48C351b5f2F1AE3a9D7758',
      earnContractAddress: '0xf856D98e7B380e939e48C351b5f2F1AE3a9D7758',
      oracle: 'lps',
      oracleId: 'spirit-bnb-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'SpiritSwap',
      assets: ['BNB', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM/D67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=D67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    },
    {
      id: 'spirit-zoo-wftm',
      logo: 'hyper/zoo-ftm.svg',
      name: 'ZOO-FTM LP',
      token: 'ZOO-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0xDF18DD2631f02D930071DF7d6FF89bbc3718C62F',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritZooFtm',
      earnedTokenAddress: '0xaaa43073823fa9e4c7770D500d421e6Bb035af1b',
      earnContractAddress: '0xaaa43073823fa9e4c7770D500d421e6Bb035af1b',
      oracle: 'lps',
      oracleId: 'spirit-zoo-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['ZOO', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x09e145a1d53c0045f41aeef25d8ff982ae74dd56',
    },
    {
      id: 'spirit-sushi-wftm',
      logo: 'hyper/sushi-ftm.svg',
      name: 'SUSHI-FTM LP',
      token: 'SUSHI-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x9Fe4c0CE5F533e96C2b72d852f190961AD5a7bB3',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritSushiFtm',
      earnedTokenAddress: '0x556aE295a34C0aA6DcEF23E689aaAf693671d3Eb',
      earnContractAddress: '0x556aE295a34C0aA6DcEF23E689aaAf693671d3Eb',
      oracle: 'lps',
      oracleId: 'spirit-sushi-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['SUSHI', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    },
    {
      id: 'spirit-curve-wftm',
      logo: 'hyper/crv-ftm.svg',
      name: 'CRV-FTM LP',
      token: 'CRV-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x374C8ACb146407Ef0AE8F82BaAFcF8f4EC1708CF',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritCurveFtm',
      earnedTokenAddress: '0x04097B5E42bBd8CEE6dFff131Daa1DF5c71e3058',
      earnContractAddress: '0x04097B5E42bBd8CEE6dFff131Daa1DF5c71e3058',
      oracle: 'lps',
      oracleId: 'spirit-curve-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['CRV', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x1E4F97b9f9F913c46F1632781732927B9019C68b',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    },
    {
      id: 'spirit-fusdt-wftm',
      logo: 'hyper/usdt-ftm.svg',
      name: 'USDT-FTM LP',
      token: 'USDT-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0xd14Dd3c56D9bc306322d4cEa0E1C49e9dDf045D4',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritUsdtFtm',
      earnedTokenAddress: '0x0AD4dE41e29417833C62B2A1b370230Ba16100C2',
      earnContractAddress: '0x0AD4dE41e29417833C62B2A1b370230Ba16100C2',
      oracle: 'lps',
      oracleId: 'spirit-fusdt-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'SpiritSwap',
      assets: ['USDT', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
    },
    {
      id: 'spirit-ice-wftm',
      logo: 'hyper/ice-ftm.svg',
      name: 'ICE-FTM LP',
      token: 'ICE-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x936D23C83c2469f6a14B9f5bEaec13879598A5aC',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritIceFtm',
      earnedTokenAddress: '0xE6e8cAB34b3838BF4e7868436B68Bf9eCeF37d2B',
      earnContractAddress: '0xE6e8cAB34b3838BF4e7868436B68Bf9eCeF37d2B',
      oracle: 'lps',
      oracleId: 'spirit-ice-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['ICE', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0xf16e81dce15b08f326220742020379b855b87df9',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0xf16e81dce15b08f326220742020379b855b87df9',
    },
    {
      id: 'spirit-cream-wftm',
      logo: 'hyper/cream-ftm.svg',
      name: 'CREAM-FTM LP',
      token: 'CREAM-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x040dd0d0f5e2a01fEb0C5457AbB588B23Cf4c43a',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritCreamFtm',
      earnedTokenAddress: '0x8491c740ecCD02f49a766eDaA12401f9632ca329',
      earnContractAddress: '0x8491c740ecCD02f49a766eDaA12401f9632ca329',
      oracle: 'lps',
      oracleId: 'spirit-cream-wftm',
      oraclePrice: 0,
      depositsPaused: true,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['CREAM', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
    },
    {
      id: 'spirit-busd-fusdt',
      logo: 'hyper/busd-usdt.svg',
      name: 'BUSD-USDT LP',
      token: 'BUSD-USDT LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x1Ac51904cFAaD15679B3500F0fC41D2971657f80',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritBusdfUsdt',
      earnedTokenAddress: '0xB56bA57Ec980429fC7Eeb4fc6f474A8C6449EBBC',
      earnContractAddress: '0xB56bA57Ec980429fC7Eeb4fc6f474A8C6449EBBC',
      oracle: 'lps',
      oracleId: 'spirit-busd-fusdt',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'SpiritSwap',
      assets: ['BUSD', 'fUSDT'],
      callFee: 0.5,
      addLiquidityUrl:
        'https://swap.spiritswap.finance/add/0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50/0x049d68029688eabf473097a2fc38ef61633a3c7a',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0xc931f61b1534eb21d8c11b24f3f5ab2471d4ab50',
    },
    {
      id: 'spirit-cover-wftm',
      logo: 'hyper/cover-ftm.svg',
      name: 'COVER-FTM LP',
      token: 'COVER-FTM LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x5427E7AB61145c41b1e58453121245dAEF3929C3',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritCoverFtm',
      earnedTokenAddress: '0x862E71953b5C407C3aa9C1d283D7BA2F77Add773',
      earnContractAddress: '0x862E71953b5C407C3aa9C1d283D7BA2F77Add773',
      oracle: 'lps',
      oracleId: 'spirit-cover-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['COVER', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl: 'https://swap.spiritswap.finance/#/add/FTM//0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    },
    {
      id: 'spirit-gton-usdc',
      logo: 'hyper/gton-usdc.svg',
      name: 'GTON-USDC LP',
      token: 'GTON-USDC LP',
      tokenDescription: 'SpiritSwap',
      tokenAddress: '0x8a5555c4996B72E5725Cf108Ad773Ce5E715DED4',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperSpiritGtonUsdc',
      earnedTokenAddress: '0x22223F35e4209f91bFAcEb979A0abf2dc1c7f276',
      earnContractAddress: '0x22223F35e4209f91bFAcEb979A0abf2dc1c7f276',
      oracle: 'lps',
      oracleId: 'spirit-gton-usdc',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'SpiritSwap',
      assets: ['GTON', 'USDC'],
      callFee: 0.5,
      addLiquidityUrl:
        'https://swap.spiritswap.finance/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
      buyTokenUrl: 'https://swap.spiritswap.finance/#/swap?outputCurrency=0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
    },
    {
      id: 'wild-eth-wftm',
      logo: 'hyper/eth-ftm.svg',
      name: 'ETH-FTM LP',
      token: 'ETH-FTM LP',
      tokenDescription: 'ZooCoin',
      tokenAddress: '0xC89dbCB06A74BD2Ec521A3e49e192906C2CC5512',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpWildEthFtm',
      earnedTokenAddress: '0xf1B7e6189BAaf0B7D5D6856a35B18b63EC8c2996',
      earnContractAddress: '0xf1B7e6189BAaf0B7D5D6856a35B18b63EC8c2996',
      oracle: 'lps',
      oracleId: 'wild-eth-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'active',
      platform: 'ZooCoin',
      assets: ['ETH', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl:
        'https://dex.zoocoin.cash/pool/add?inputCurrency=FTM&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
      buyTokenUrl:
        'https://dex.zoocoin.cash/orders/market?inputCurrency=FTM&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    },
    {
      id: 'wild-wild-wftm',
      logo: 'hyper/wild-ftm.svg',
      name: 'WILD-FTM LP',
      token: 'WILD-FTM LP',
      tokenDescription: 'ZooCoin',
      tokenAddress: '0x80C70A23AB388c3B8624f8FC9dBfB4ee469A69a1',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpWildWildFtm',
      earnedTokenAddress: '0x08E671927ecaC1Ab05e06b4AAD8167D4707cc530',
      earnContractAddress: '0x08E671927ecaC1Ab05e06b4AAD8167D4707cc530',
      oracle: 'lps',
      oracleId: 'wild-wild-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'ZooCoin',
      assets: ['WILD', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl:
        'https://dex.zoocoin.cash/pool/add?inputCurrency=FTM&outputCurrency=0xae0c241ec740309c2cbdc27456eb3c1a2ad74737',
      buyTokenUrl:
        'https://dex.zoocoin.cash/orders/market?inputCurrency=FTM&outputCurrency=0xae0c241ec740309c2cbdc27456eb3c1a2ad74737',
    },
    {
      id: 'wild-usdc-wftm',
      logo: 'hyper/usdc-ftm.svg',
      name: 'USDC-FTM LP',
      token: 'USDC-FTM LP',
      tokenDescription: 'ZooCoin',
      tokenAddress: '0x38E3AD31EC5eee7D0908353231E639849E1510c0',
      tokenDecimals: 18,
      tokenDescriptionUrl: 'https://hyperjump.fi',
      earnedToken: 'hyperjumpWildUsdcFtm',
      earnedTokenAddress: '0x30ffC11BD3450A40D4D4eEF24B76BADcEDEEC085',
      earnContractAddress: '0x30ffC11BD3450A40D4D4eEF24B76BADcEDEEC085',
      oracle: 'lps',
      oracleId: 'wild-wild-wftm',
      oraclePrice: 0,
      depositsPaused: false,
      status: 'eol',
      platform: 'ZooCoin',
      assets: ['WILD', 'FTM'],
      callFee: 0.5,
      addLiquidityUrl:
        'https://dex.zoocoin.cash/pool/add?inputCurrency=FTM&outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      buyTokenUrl:
        'https://dex.zoocoin.cash/orders/market?inputCurrency=FTM&outputCurrency=0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    },
  ],
}

export default vaults
